import { useState, useEffect } from "react";
import "./FixturesAndResults.scss";
import Disney from "../../Assets/disney-hotstar.svg";
import { ReactComponent as Field } from "../../Assets/Icons/field.svg";
import { ReactComponent as Graph } from "../../Assets/Icons/bar-graph.svg";
import Panther from "../../Assets/pink-panther-logo.png";
import { useTransition, a } from "react-spring";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import CONSTANTS from "../../Constants";
import { PREVIOUS_MATCH_ID } from "../../utils/seasonConstants";

const types = ["past", "upcoming"];

export default function FixturesAndResults({ data, reverse, index }) {
  const [second1, setSecond1] = useState(0);
  const [second2, setSecond2] = useState(0);
  const [minute1, setMinute1] = useState(0);
  const [minute2, setMinute2] = useState(0);
  const [hour1, setHour1] = useState(0);
  const [hour2, setHour2] = useState(0);
  const [day1, setDay1] = useState(0);
  const [day2, setDay2] = useState(0);


  const type = data.result === "TBD" ? "upcoming" : "past";

  const ReturnTransitions = (item, screenWidth) => {
    const transitions = useTransition(item, {
      from: {
        transform:
          screenWidth > 500 ? "translateY(-4rem)" : "translateY(-2.5rem)",
      },
      enter: { transform: "translateY(0rem)" },
      leave: {
        transform:
          screenWidth > 500 ? "translateY(2.5rem)" : "translateY(4rem)",
      },
    });

    return transitions;
  };

  const second1Trans = ReturnTransitions(second1);
  const second2Trans = ReturnTransitions(second2);
  const minute1Trans = ReturnTransitions(minute1);
  const minute2Trans = ReturnTransitions(minute2);
  const hour1Trans = ReturnTransitions(hour1);
  const hour2Trans = ReturnTransitions(hour2);
  const day1Trans = ReturnTransitions(day1);
  const day2Trans = ReturnTransitions(day2);

  useEffect(() => {
    const updateTime = () => {
      const currentTime = new Date().getTime();
      const eventTime = new Date(data.startTime).getTime();
      const timeDifference = eventTime - currentTime;

      if (timeDifference <= 0) {
        // Event has already started
        setSecond2(0);
        setSecond1(0);
        setMinute2(0);
        setMinute1(0);
        setHour2(0);
        setHour1(0);
        setDay2(0);
        setDay1(0);
      } else {
        const totalSeconds = Math.floor(timeDifference / 1000) % 60;
        const totalMinutes = Math.floor(timeDifference / 1000 / 60) % 60;
        const totalHours = Math.floor(timeDifference / 1000 / 60 / 60) % 24;
        const totalDays = Math.floor(timeDifference / 1000 / 60 / 60 / 24);

        setSecond2(totalSeconds % 10);
        setSecond1(Math.floor((totalSeconds % 60) / 10));
        setMinute2(totalMinutes % 10);
        setMinute1(Math.floor((totalMinutes % 60) / 10));
        setHour2(totalHours % 10);
        setHour1(Math.floor((totalHours % 24) / 10));
        setDay2(totalDays % 10);
        setDay1(Math.floor(totalDays / 10));
      }
    };

    const interval = setInterval(() => updateTime(), 1000);

    return () => clearInterval(interval);
  }, [data.startTime]);

  return (
    <div className={`fixture ${type}`}>
      <div className="info">
        <div className="title">
          <p >{type === "past" ? "RESULT" : "UPCOMING"}</p>
          <div className="row">
            <p>{`${data.tournament.name}, ${format(
              Date.parse(data.startTime),
              "dd/MM"
            )}`}</p>
            <p>{format(Date.parse(data.startTime), "h:mm aa")}</p>
          </div>
        </div>
        {reverse == true ? <div className="main">
          <div className="teams">
            <div className="item">
              <p>{data.team.name}</p>
              <img
                src={CONSTANTS.createUrl(data.team.icon.url)}
                alt={data.team.icon.alternativeText}
              />
            </div>
            {type === "past" ? (
              <p className="score">{`${data.opponentScore}-${data.jppScore}`}</p>
            ) : (
              <p className="vs-text">VS</p>
            )}
            <div className="item">
              <p >JAIPUR PINK PANTHERS</p>
              <img src={Panther} alt="pink panther logo" />
            </div>
          </div>
          {type === "upcoming" ? (
            <div className="timer">
              <div className="time">
                {day1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {day2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">D</p>

              <div className="time">
                {hour1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {hour2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">H</p>

              <div className="time">
                {minute1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {minute2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">M</p>

              <div className="time">
                {second1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {second2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">S</p>
            </div>
          ) : null}
        </div> : <div className="main">
          <div className="teams">
            <div className="item">
              <p>JAIPUR PINK PANTHERS</p>
              <img src={Panther} alt="pink panther logo" />
            </div>
            {type === "past" ? (
              <p className="score">{`${data.jppScore}-${data.opponentScore}`}</p>
            ) : (
              <p className="vs-text">VS</p>
            )}
            <div className="item">
              <p>{data.team.name}</p>
              <img
                src={CONSTANTS.createUrl(data.team.icon.url)}
                alt={data.team.icon.alternativeText}
              />
            </div>
          </div>
          {type === "upcoming" ? (
            <div className="timer">
              <div className="time">
                {day1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {day2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">D</p>

              <div className="time">
                {hour1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {hour2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">H</p>

              <div className="time">
                {minute1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {minute2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">M</p>

              <div className="time">
                {second1Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <div className="time">
                {second2Trans((styles, item) => (
                  <a.p style={styles}>{item}</a.p>
                ))}
              </div>
              <p className="unit">S</p>
            </div>
          ) : null}
        </div>}
      </div>
      {
        type === "past" ? (
          <div className="buttons">
            <Link className="hub" to={`/matches/${data.id}`}>
              <Field style={{ fill: "var(--secondaryColor" }} />
              <p>MATCH HUB</p>
            </Link>
            {data.streamUrl ? (
              <a
                className="stream"
                href={data.streamUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img src={Disney} alt="" />
                <p>HIGHLIGHTS</p>
              </a>
            ) : null}
          </div>
        ) : (
          <div className="buttons">
            <Link className="stats" to={`/matches/${PREVIOUS_MATCH_ID[data.team.name.trim()]}`}>
              <Graph />
              <p>PREVIOUS STATS</p>
            </Link>
          </div>
        )
      }
    </div >
  );
}
