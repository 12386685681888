import { useState, useEffect, useRef } from 'react';
import './Standings.scss';
import Dropdown from '../../Components/UI/Dropdown/Dropdown';
import StandingsMobileTeam from '../../Components/StandingsMobileTeam/StandingsMobileTeam';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import CONSTANTS from '../../Constants';
import PageTransition from '../../Components/PageTransition/PageTransition';
import { ReactComponent as Trophy } from '../../Assets/Icons/trophy.svg';
import { useHistory, useLocation } from 'react-router';

const SEASONS_QUERY = gql`
    query SeasonsQuery{
        tournaments(sort: "startDate:desc"){
            name,
            id
        }
    }
`;

const STANDINGS_QUERY = gql`
query StandingsPage($id: ID!){
    tournament(id: $id){
        id,
        name,
        winner{
            id
            isPanther
        }
        teamStandings{
            team{
                id,
                name,
                icon{
                    url,
                    alternativeText
                }
                isPanther
            },
            position,
            points,
            matches,
            wins,
            losses,
            draws,
            difference,
            hasQualified
        },
        subDivision{
            name,
            teamStandings{
                team{
                    id,
                    name,
                    icon{
                        url,
                        alternativeText
                    }
                    isPanther
                },
                position,
                points,
                matches,
                wins,
                losses,
                draws,
                difference,
                hasQualified
            }
        }
    }
}`;


const STANDINGS_PAGE_QUERY = gql`
query StandingsPage{
    standingsPage{
        landingMedia{
            url,
            alternativeText
        }
    }
}`;

export default function Standings({ setProgress, now }) {
    // const now = useRef(new Date());
    const [seasonValue, setSeasonValue] = useState(null);
    const [seasonId, setSeasonId] = useState(null);
    const [standings, setStandings] = useState(null);
    const [subDivisions, setSubDivisions] = useState(null);
    const [mobileData, setMobileData] = useState(null);
    const [dropdownOptions, setDropdownOptions] = useState(null);
    const [winner, setWinner] = useState(null);

    const { data: seasonsData } = useQuery(SEASONS_QUERY);

    const [getStandings, { data: standingsData, loading: standingsLoading }] = useLazyQuery(STANDINGS_QUERY, {
        variables: {
            id: parseInt(seasonId)
        }
    })

    const { data, error, loading } = useQuery(STANDINGS_PAGE_QUERY);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const selection = new URL(window.location.href).searchParams.get("tournament");
        if (seasonsData) {
            if (selection) {
                if (seasonsData.tournaments.findIndex(item => item.id === selection) === -1) {
                    history.replace(`/standings?tournament=${seasonsData.tournaments[0].id}`);
                } else {
                    setSeasonId(new URL(window.location.href).searchParams.get("tournament"));
                    const dropdownOptions = seasonsData.tournaments.map(item => {
                        return { text: item.name, value: parseInt(item.id) }
                    });
                    setSeasonValue(parseInt(selection));
                    setDropdownOptions(dropdownOptions);
                }
            } else {
                history.replace(`/standings?tournament=${seasonsData.tournaments[0].id}`);
            }
        }
    }, [location.search, seasonsData]);

    useEffect(() => {
        if (seasonId) {
            getStandings();
        }
    }, [seasonId]);

    useEffect(() => {
        if (seasonValue) {
            history.replace(`/standings?tournament=${seasonValue}`);
        }
    }, [seasonValue]);

    useEffect(() => {
        if (standingsData) {
            const tournament = standingsData.tournament;
            if (tournament.teamStandings.length) {
                const standings = [...tournament.teamStandings].sort((a, b) => a.position - b.position);
                setStandings(standings);
                setSubDivisions(null);
                setWinner(tournament.winner[0]);
            } else if (tournament.subDivision.length) {
                const subDivisions = tournament.subDivision.map(sub => {
                    return {
                        name: sub.name,
                        teamStandings: [...sub.teamStandings].sort((a, b) => a.position - b.position)
                    }
                });
                setWinner(tournament.winner[0]);
                setSubDivisions(subDivisions);
                setStandings(null);
            }
            setProgress(prog => prog + 60);
        }
    }, [standingsData]);

    useEffect(() => {
        if (standings) {
            const maxValues = {
                matches: Math.max(...standings.map(item => item.matches)),
                wins: Math.max(...standings.map(item => item.wins)),
                losses: Math.max(...standings.map(item => item.losses)),
                draws: Math.max(...standings.map(item => item.draws)),
                difference: Math.max(...standings.map(item => item.difference)),
                points: Math.max(...standings.map(item => item.points)),
            };
            const mobileData = standings.map(item => {
                const returnItem = {
                    id: item.team.id,
                    hasQualified: item.hasQualified,
                    isPanther: item.team.isPanther,
                    img: item.team.icon,
                    position: item.position,
                    data: Object.keys(maxValues).map(key => {
                        return { title: key, value: item[key], maxValue: maxValues[key] }
                    })
                };
                return returnItem
            });
            setMobileData([{ teamStandings: mobileData }]);
        } else if (subDivisions) {
            const maxValues = {
                matches: Math.max(...subDivisions.flatMap(sub => sub.teamStandings.map(item => item.matches))),
                wins: Math.max(...subDivisions.flatMap(sub => sub.teamStandings.map(item => item.wins))),
                losses: Math.max(...subDivisions.flatMap(sub => sub.teamStandings.map(item => item.losses))),
                draws: Math.max(...subDivisions.flatMap(sub => sub.teamStandings.map(item => item.draws))),
                difference: Math.max(...subDivisions.flatMap(sub => sub.teamStandings.map(item => item.difference))),
                points: Math.max(...subDivisions.flatMap(sub => sub.teamStandings.map(item => item.points))),
            };
            const mobileData = subDivisions.map(sub => {
                const returnItem = {
                    name: sub.name,
                    teamStandings: sub.teamStandings.map(item => {
                        const returnItem = {
                            id: item.team.id,
                            hasQualified: item.hasQualified,
                            isPanther: item.team.isPanther,
                            img: item.team.icon,
                            position: item.position,
                            data: Object.keys(maxValues).map(key => {
                                return { title: key, value: item[key], maxValue: maxValues[key] }
                            })
                        };
                        return returnItem
                    })
                };
                return returnItem
            });
            setMobileData(mobileData);
        }
    }, [standings]);

    // useEffect(() => {
    //     if(data){
    //         const dropdownOptions = data.tournaments.map(item => {
    //             return {text: item.name, value: parseInt(item.id)}
    //         });
    //         setDropdownOptions(dropdownOptions);
    //         setSeasonValue(dropdownOptions[0].value);
    //         setProgress(prog => prog + 60);
    //     }
    // }, [data]);

    // useEffect(() => {
    //     if(data && dropdownOptions && (seasonValue !== null)){
    //         const tournament = data.tournaments.filter(item => parseInt(item.id) === seasonValue)[0];
    //         if(tournament.teamStandings.length){
    //             const standings = [...tournament.teamStandings].sort((a, b) => a.position - b.position);
    //             setStandings(standings);
    //             setSubDivisions(null);
    //             setWinner(tournament.winner[0]);
    //         } else if(tournament.subDivision.length){
    //             const subDivisions = tournament.subDivision.map(sub => {
    //                 return {
    //                     name: sub.name,
    //                     teamStandings: [...sub.teamStandings].sort((a, b) => a.position - b.position)
    //                 }
    //             });
    //             setWinner(tournament.winner[0]);
    //             setSubDivisions(subDivisions);
    //             setStandings(null);
    //         }
    //     }
    // }, [seasonValue, dropdownOptions, data]);

    const detectUrlType = (string) => {
        const images = ["jpg", "gif", "png"]
        const videos = ["mp4", "3gp", "ogg"]

        const url = new URL(CONSTANTS.createUrl(string));
        const extension = url.pathname.split(".").slice(-1)[0];

        if (images.includes(extension)) {
            return 'image';
        } else if (videos.includes(extension)) {
            return 'video';
        }
    };

    console.log(mobileData);

    return (
        <>
            <div className="standings-page">
                <div className="section-1">
                    {(data && data.standingsPage && data.standingsPage.landingMedia) ?
                        ((detectUrlType(data.standingsPage.landingMedia.url) === 'image') ?
                            <img
                                className="background"
                                src={CONSTANTS.createUrl(data.standingsPage.landingMedia.url)}
                                alt={data.standingsPage.landingMedia.alternativeText}
                            />
                            : <video
                                className="background"
                                src={CONSTANTS.createUrl(data.standingsPage.landingMedia.url)}
                                autoPlay={true}
                                preload='auto'
                                muted
                                loop={true}
                            />)
                        : null
                    }
                    {/* <img className="background" src={TitleImage} alt="players entering the kabaddi field" /> */}
                    <div className="title-and-controls">
                        <h1>STANDINGS</h1>
                        {dropdownOptions && <Dropdown options={dropdownOptions} value={seasonValue} setValue={setSeasonValue} />}
                    </div>
                </div>
                <div className="section-2-desktop">
                    {(standings && standings.length) ?
                        <div className="table">
                            <div className="row title">
                                <div className="position">POSITION</div>
                                <div className="club">CLUB</div>
                                <div className="matches">MATCHES</div>
                                <div className="wins">WINS</div>
                                <div className="losses">LOSSES</div>
                                <div className="draws">DRAWS</div>
                                <div className="difference">DIFFERENCE</div>
                                <div className="points">POINTS</div>
                                {/* <div className="form">FORM</div> */}
                            </div>
                            {standings && standings.map(item =>
                                <div className={`row${item.team.isPanther ? ' highlighted' : ''}`}>
                                    <div className="position">{item.position.toString().padStart(2, '0')}</div>
                                    <div className={`club ${(item.team.id === (winner && winner.id)) ? 'winner' : ''}`}>
                                        {(item.team.id === (winner && winner.id)) ?
                                            <Trophy />
                                            : <div className={`qualified-text ${item.hasQualified ? 'visible' : ''}`}>Q</div>
                                        }
                                        <img src={CONSTANTS.createUrl(item.team.icon.url)} alt={item.team.icon.alternativeText} />
                                        <p>{item.team.name.toUpperCase()}</p>
                                    </div>
                                    <div className="matches">
                                        <p>{item.matches.toString().padStart(2, '0')}</p>
                                    </div>
                                    <div className="wins">
                                        <p>{item.wins.toString().padStart(2, '0')}</p>
                                    </div>
                                    <div className="losses">
                                        <p>{item.losses.toString().padStart(2, '0')}</p>
                                    </div>
                                    <div className="draws">
                                        <p>{item.draws.toString().padStart(2, '0')}</p>
                                    </div>
                                    <div className="difference">
                                        <p>{item.difference.toString().padStart(2, '0')}</p>
                                    </div>
                                    <div className="points">
                                        <p>{item.points.toString().padStart(2, '0')}</p>
                                    </div>
                                    {/* <div className="form">
                                        {item.form.map(formItem => 
                                            <div className={`item ${formItem.matchResult.toLowerCase()}`} />
                                        )}
                                    </div> */}
                                </div>
                            )}
                        </div> : null
                    }
                    {(subDivisions && subDivisions.length) ?
                        subDivisions.map(sub =>
                            <>
                                <h2>{sub.name}</h2>
                                <div className="table">
                                    <div className="row title">
                                        <div className="position">POSITION</div>
                                        <div className="club">CLUB</div>
                                        <div className="matches">MATCHES</div>
                                        <div className="wins">WINS</div>
                                        <div className="losses">LOSSES</div>
                                        <div className="draws">DRAWS</div>
                                        <div className="difference">DIFFERENCE</div>
                                        <div className="points">POINTS</div>
                                    </div>
                                    {sub.teamStandings.map(item =>
                                        <div className={`row${item.team.isPanther ? ' highlighted' : ''}`}>
                                            <div className="position">{item.position.toString().padStart(2, '0')}</div>
                                            <div className={`club ${(item.team.id === (winner && winner.id)) ? 'winner' : ''}`}>
                                                {(item.team.id === (winner && winner.id)) ?
                                                    <Trophy />
                                                    : <div className={`qualified-text ${item.hasQualified ? 'visible' : ''}`}>Q</div>
                                                }
                                                <img src={CONSTANTS.createUrl(item.team.icon.url)} alt={item.team.icon.alternativeText} />
                                                <p>{item.team.name.toUpperCase()}</p>
                                            </div>
                                            <div className="matches">
                                                <p>{item.matches.toString().padStart(2, '0')}</p>
                                            </div>
                                            <div className="wins">
                                                <p>{item.wins.toString().padStart(2, '0')}</p>
                                            </div>
                                            <div className="losses">
                                                <p>{item.losses.toString().padStart(2, '0')}</p>
                                            </div>
                                            <div className="draws">
                                                <p>{item.draws.toString().padStart(2, '0')}</p>
                                            </div>
                                            <div className="difference">
                                                <p>{item.difference.toString().padStart(2, '0')}</p>
                                            </div>
                                            <div className="points">
                                                <p>{item.points.toString().padStart(2, '0')}</p>
                                            </div>
                                            {/* <div className="form">
                                                {item.form.map(formItem => 
                                                    <div className={`item ${formItem.matchResult.toLowerCase()}`} />
                                                )}
                                            </div> */}
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : null
                    }
                </div>
                {mobileData ? <div className="section-2-mobile">
                    {mobileData.map(sub =>
                        <>
                            {sub.name ? <h2>{sub.name}</h2> : null}
                            <div className="table">
                                {sub.teamStandings.map(item => {
                                    console.log(item);
                                    return <StandingsMobileTeam team={item} winner={winner} />
                                })}
                            </div>
                        </>
                    )}
                </div> : null}
            </div>
            {(!standingsData || standingsLoading) ? <PageTransition /> : null}
        </>
    )
}