export const reverseNameMatchesIndex = [0, 1, 2, 4, 7, 13, 17, 16, 18, 21]
export const reverseMatchesIdSeason11 = ['205', '206', '207', '210', '212', '217', '218', '223', '222', '226', '221', '229']
export const PREVIOUS_MATCH_ID = {
    'Haryana Steelers': '204',
    'Gujarat Giants': '213',
    'U.P. Yoddhas': '218',
    'Patna Pirates': '211',
    'Tamil Thalaivas': '208',
    'Bengaluru Bulls': '212',
    'Bengal Warriorz': '205',
    'Puneri Paltan': '199',
    'Telugu Titans': '206',
    'Dabang Delhi K.C.': '215',
    'U Mumba': '210'
}